import React from "react";
import { Button, FormContainer, FormItem, Input } from "components/ui";
import { Field, Form, Formik } from "formik";

export default function PlanoSaude({
  defaultValues,
  onSubmit,
  onBack,
  isSubmitting,
  onClickLimpar,
}) {
  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={(values) => onSubmit(values)}
    >
      <Form className="w-full">
        <FormContainer>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
            <FormItem label="Nome do plano de saúde">
              <Field
                type="text"
                autoComplete="off"
                name="nomePlano"
                placeholder="Nome do plano de saúde"
                component={Input}
              />
            </FormItem>
            <FormItem label="Código">
              <Field
                type="text"
                autoComplete="off"
                name="codigoPlano"
                placeholder="Código"
                component={Input}
              />
            </FormItem>
            <FormItem label="Validade do plano de saúde">
              <Field
                type="date"
                autoComplete="off"
                name="validadePlano"
                placeholder="Validade do plano de saúde"
                component={Input}
              />
            </FormItem>
          </div>

          <FormItem>
            <div className="flex justify-between mt-4">
              <Button type="button" onClick={onClickLimpar}>
                Limpar
              </Button>
              <div className="flex gap-2">
                <Button onClick={onBack} disabled={isSubmitting}>
                  Voltar
                </Button>
                <Button type="submit" variant="solid" loading={isSubmitting}>
                  Próximo
                </Button>
              </div>
            </div>
          </FormItem>
        </FormContainer>
      </Form>
    </Formik>
  );
}
