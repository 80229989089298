import React from "react";
import { Button, FormContainer, FormItem, Input } from "components/ui";
import { Field, Form, Formik } from "formik";

import { HiOutlineMail } from "react-icons/hi";

import { PhoneInput } from "react-international-phone";

export default function ResponsavelFinanceiro({
  defaultValues,
  onSubmit,
  onBack,
  onClickLimpar,
}) {
  return (
    <Formik
      initialValues={defaultValues}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ errors, values, setFieldValue }) => {
        return (
          <Form className="w-full">
            <FormContainer>
              <FormItem label="Nome do Responsável Financeiro">
                <Field
                  type="text"
                  name="nomeResponsavelFinanceiro"
                  placeholder="Nome do Responsável Financeiro"
                  component={Input}
                  prefix={<HiOutlineMail className="text-xl" />}
                />
              </FormItem>

              <FormItem
                label="Telefone do responsável financeiro"
                invalid={errors.telefoneResponsavelFinanceiro}
                errorMessage={errors.telefoneResponsavelFinanceiro}
              >
                <PhoneInput
                  inputStyle={{
                    fontSize: "15px",
                    color: "gray",
                    border: "none",
                  }}
                  countrySelectorStyleProps={{
                    buttonStyle: { border: "none" },
                  }}
                  className="input py-[1.7px]"
                  defaultCountry="br"
                  name="telefoneResponsavelFinanceiro"
                  value={values.telefoneResponsavelFinanceiro}
                  onChange={(e) =>
                    setFieldValue("telefoneResponsavelFinanceiro", e)
                  }
                />
              </FormItem>
              <FormItem>
                <div className="flex justify-between">
                  <Button type="button" onClick={onClickLimpar}>
                    Limpar
                  </Button>
                  <div className="flex gap-2">
                    <Button onClick={onBack}>Voltar</Button>
                    <Button type="submit" variant="solid">
                      Próximo
                    </Button>
                  </div>
                </div>
              </FormItem>
            </FormContainer>
          </Form>
        );
      }}
    </Formik>
  );
}
