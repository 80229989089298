import React from "react";
import {
  Button,
  FormContainer,
  FormItem,
  Input,
  Notification,
  toast,
} from "components/ui";
import { Field, Form, Formik } from "formik";

import {
  HiOutlineBriefcase,
  HiOutlineCalendar,
  HiOutlineIdentification,
  HiOutlineMail,
  HiOutlineUserCircle,
} from "react-icons/hi";

import { PhoneInput } from "react-international-phone";

import InputMask from "react-input-mask";
import { t } from "i18next";

import * as Yup from "yup";
import personalDocuments from "utils/personalDocuments";
import validateDocument from "utils/validate";

const validationSchema = Yup.object().shape({
  nome: Yup.string()
    .min(3, "Muito curto!")
    .required("Digite seu nome completo"),
  email: Yup.string().email("E-mail inválido").required("Digite seu e-mail"),
  telefone: Yup.string()
    .min(8, "Telefone inválido")
    .required("Difite seu telefone"),
  cpf: Yup.string().required("Digite seu documento"),
});

export default function DadosPessoais({
  defaultValues,
  onSubmit,
  onClickLimpar,
}) {
  return (
    <Formik
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        const pais = values.telefone.startsWith("+351") ? "PT" : "BR";
        const documentType = personalDocuments[pais].name;

        if (!validateDocument(values.cpf, documentType)) {
          toast.push(
            <Notification type="danger">
              O {documentType} digitado é inválido
            </Notification>,
            {
              placement: "top-center",
            }
          );

          return;
        }

        onSubmit(values);
      }}
    >
      {({ errors, values, setFieldValue, touched }) => {
        const pais = values.telefone?.startsWith("+351") ? "PT" : "BR";
        const documentType = personalDocuments[pais].name;

        return (
          <Form className="w-full">
            <FormContainer>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                <FormItem
                  label="Nome do paciente"
                  invalid={errors.nome && touched.nome}
                  errorMessage={errors.nome}
                >
                  <Field
                    type="text"
                    autoComplete="off"
                    name="nome"
                    placeholder="Nome do Cliente"
                    component={Input}
                    prefix={<HiOutlineUserCircle className="text-xl" />}
                  />
                </FormItem>

                <FormItem label="Profissão">
                  <Field
                    type="text"
                    autoComplete="off"
                    name="profissao"
                    placeholder="Profissão"
                    component={Input}
                    prefix={<HiOutlineBriefcase className="text-xl" />}
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                <FormItem
                  label="E-mail"
                  invalid={errors.email && touched.email}
                  errorMessage={errors.email}
                >
                  <Field
                    type="email"
                    name="email"
                    placeholder="Email"
                    component={Input}
                    prefix={<HiOutlineMail className="text-xl" />}
                  />
                </FormItem>

                <FormItem
                  label="Telefone"
                  invalid={errors.telefone && touched.telefone}
                  errorMessage={errors.telefone}
                >
                  <PhoneInput
                    inputStyle={{
                      fontSize: "15px",
                      color: "gray",
                      border: "none",
                    }}
                    countrySelectorStyleProps={{
                      buttonStyle: { border: "none" },
                    }}
                    className="input py-[1.7px]"
                    defaultCountry="br"
                    name="telefone"
                    value={values.telefone}
                    onChange={(e) => setFieldValue("telefone", e)}
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                <FormItem
                  label={documentType}
                  invalid={errors.cpf && touched.cpf}
                  errorMessage={errors.cpf}
                >
                  <Field name="cpf">
                    {({ field }) => (
                      <div>
                        <InputMask
                          {...field}
                          mask={personalDocuments[pais].mask}
                          placeholder={`${documentType} (somente números)`}
                        >
                          {(inputProps) => (
                            <Input
                              {...inputProps}
                              type="text"
                              autoComplete="off"
                              prefix={
                                <HiOutlineIdentification className="text-xl" />
                              }
                            />
                          )}
                        </InputMask>
                      </div>
                    )}
                  </Field>
                </FormItem>

                <FormItem label="Data de Nascimento">
                  <Field
                    type="date"
                    name="dataNascimento"
                    placeholder="Data de Nascimento"
                    component={Input}
                    prefix={<HiOutlineCalendar className="text-xl" />}
                  />
                </FormItem>
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
                <FormItem label="Gênero">
                  <Field
                    type="text"
                    autoComplete="off"
                    name="genero"
                    placeholder="Gênero"
                    as="select"
                    className={`input input-md h-11 bg-white`}
                  >
                    <option value="" selected>
                      Gênero
                    </option>
                    <option value="MASCULINO">Masculino</option>
                    <option value="FEMININO">Feminino</option>
                    <option value="OUTRO">Outro</option>
                  </Field>
                </FormItem>
              </div>

              <FormItem>
                <div className="flex justify-end gap-2">
                  <Button type="button" onClick={onClickLimpar}>
                    Limpar
                  </Button>
                  <Button variant="solid" type="submit">
                    Próximo
                  </Button>
                </div>
              </FormItem>
            </FormContainer>
          </Form>
        );
      }}
    </Formik>
  );
}
