import React, { useEffect, useState } from "react";
import { Button, Notification, Steps, toast } from "components/ui";

import { DateTime } from "luxon";

import {
  _atualizarCliente,
  _buscarPacientePorId,
  _salvarNovoContato,
  _salvarNovoPaciente,
} from "services/PacienteService";

import DadosPessoais from "./FormSteps/DadosPessoais";
import ResponsavelFinanceiro from "./FormSteps/ResponsavelFinanceiro";
import Endereco from "./FormSteps/Endereco";
import PlanoSaude from "./FormSteps/PlanoSaude";

import { Contatos } from "./Contatos";

const lsDataNames = {
  DADOS_PESSOAIS: "dados_pessoais",
  RESPONSAVEL_FINANCEIRO: "resp_financeiro",
  ENDERECO: "endereco",
  PLANO_SAUDE: "planoSaude",
  CONTATOS: "contacts",
};

const setItemToLS = (key, data) =>
  localStorage.setItem(key, JSON.stringify(data));
const getItemFromLS = (key) => JSON.parse(localStorage.getItem(key));

export default function CadastrarPaciente({ uuidProfissional, urlAgenda }) {
  const [stage, setStage] = useState(0);
  const [dadosPessoais, setDadosPessoais] = useState({
    nome: "",
    profissao: "",
    email: "",
    telefone: "",
    cpf: "",
    dataNascimento: "",
  });
  const [responsavelFinanceiro, setResponsavelFinanceiro] = useState({});
  const [endereco, setEndereco] = useState({});
  const [planoSaude, setPlanoSaude] = useState({});
  const [contacts, setContacts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const lsDadosPessoais = getItemFromLS(lsDataNames.DADOS_PESSOAIS),
      lsRespFinanceiro = getItemFromLS(lsDataNames.RESPONSAVEL_FINANCEIRO),
      lsEndereco = getItemFromLS(lsDataNames.ENDERECO),
      lsPlanoSaude = getItemFromLS(lsDataNames.PLANO_SAUDE),
      lsContatos = getItemFromLS(lsDataNames.CONTATOS);

    !!lsDadosPessoais && setDadosPessoais(lsDadosPessoais);
    !!lsRespFinanceiro && setResponsavelFinanceiro(lsRespFinanceiro);
    !!lsEndereco && setEndereco(lsEndereco);
    !!lsPlanoSaude && setPlanoSaude(lsPlanoSaude);
    !!lsContatos && setContacts(lsContatos);
  }, []);

  const handleDeleteContact = (indexToRemove) => {
    let arr = [...contacts];
    arr = arr.filter((_, index) => index !== indexToRemove);
    setItemToLS(lsDataNames.CONTATOS, arr);

    setContacts((prevItems) =>
      prevItems.filter((_, index) => index !== indexToRemove)
    );
  };
  const onAddContact = (values) => {
    setItemToLS(lsDataNames.CONTATOS, [...contacts, values]);
    setContacts((prevState) => [...prevState, values]);
    setIsDrawerOpen(false);
  };

  const StageWrapper = ({ children, selectedStage }) => {
    if (selectedStage === stage) {
      return children;
    }
  };

  const handlePrevStage = () => setStage(stage - 1);

  const handleNextStage = () => setStage(stage + 1);

  const handleSubmitValues = async (contatos) => {
    setIsSubmitting(true);

    const payload = {
      nome: dadosPessoais.nome,
      profissao: dadosPessoais.profissao,
      email: dadosPessoais.email,
      telefone: dadosPessoais.telefone,
      cpf: dadosPessoais.cpf.replace(/\D/g, ""),
      dataNascimento: dadosPessoais.dataNascimento
      ? DateTime.fromFormat(
          dadosPessoais.dataNascimento,
          "yyyy-MM-dd"
        ).toFormat("dd/MM/yyyy")
      : "",
      genero: dadosPessoais.genero,
      nomeResponsavelFinanceiro:
        responsavelFinanceiro.nomeResponsavelFinanceiro,
      telefoneResponsavelFinanceiro:
        responsavelFinanceiro.telefoneResponsavelFinanceiro,
      cep: endereco.cep?.replace(/\D/g, ""),
      endereco: endereco.endereco,
      cidade: endereco.cidade,
      estado: endereco.estado,
      bairro: endereco.bairro,
      numero: endereco.numero,
      complemento: endereco.complemento,
      timezone: endereco.timezone,
      nomePlano: planoSaude.nomePlano,
      codigoPlano: planoSaude.codigoPlano,
      validadePlano: planoSaude.validadePlano ? DateTime.fromFormat(
        planoSaude.validadePlano,
        "yyyy-MM-dd"
      ).toFormat("dd/MM/yyyy") : "",
    };

    try {
      const response = await _salvarNovoPaciente(
        urlAgenda,
        uuidProfissional,
        payload
      );

      await Promise.all(
        contatos.map(async (contato) => {
          await handleAddContacts({
            ...contato,
            clienteId: response.data.id,
            clienteUuid: response.data.uuid,
          });
        })
      );

      toast.push(
        <Notification type="success">
          Cadastro efetuado com sucesso.
        </Notification>,
        {
          placement: "top-center",
        }
      );

      setTimeout(() => {
        setIsSubmitting(false);
        setStage(5);
      }, 500);
    } catch (error) {
      setIsSubmitting(false);
      toast.push(
        <Notification type="danger">
          Não foi possível efetuar o cadastro. Verifique os dados inseridos e
          tente novamente.
        </Notification>,
        {
          placement: "top-center",
        }
      );
    }
  };

  const stages = {
    0: "Dados pessoais",
    1: "Responsável financeiro",
    2: "Endereço",
    3: "Plano de saúde",
    4: "Contatos",
  };

  const handleAddContacts = async (contact) => {
    try {
      await _salvarNovoContato(contact);
    } catch (error) {
      toast.push(
        <Notification type="danger">
          Não foi possível salvar o contato {contact?.nome}. Verifique os dados
          inseridos e tente novamente.
        </Notification>,
        {
          placement: "top-center",
        }
      );
    }
  };

  const handleLimpar = () => {
    localStorage.clear();
    setStage(0);
    setDadosPessoais({});
    setResponsavelFinanceiro({});
    setEndereco({});
    setPlanoSaude({});
    setFinished(false);
    setIsSubmitting(false);
    setClienteNotFound(false);
    setLoading(false);
  };

  //   const Welcome = () => (
  //     <div className="flex flex-col items-center justify-center max-w-xl">
  //       <h2 className="mt-5 font-light">Bem-vindo à Corpora</h2>
  //       <p className="mb-5 text-center">
  //         Ao completar seu cadastro você ajuda o profissional a saber mais sobre
  //         você e como ele pode te atender melhor.
  //       </p>
  //       <Button variant="solid" size="md">
  //         Iniciar
  //       </Button>
  //       <p className="mt-2">
  //         <small>
  //           <a href="https://usecorpora.com.br/termos-de-uso/?_gl=1*1g5b5v9*_gcl_au*MTA4NDQzNTY0MS4xNzI5NTk3MTQx">
  //             Termos de uso
  //           </a>
  //         </small>
  //         <small> &#x2022; </small>
  //         <small>
  //           <a href="https://usecorpora.com.br/politica-de-privacidade/">
  //             Política de privacidade
  //           </a>
  //         </small>
  //       </p>
  //     </div>
  //   );

  const Completed = () => (
    <div className="flex flex-col items-center justify-center max-w-xl">
      <h2 className="mt-5 font-light">Obrigado</h2>
      <p className="text-center mt-4 mb-2">
        Seu cadastro foi efetuado com sucesso!
      </p>

      <div className="flex gap-2">
        <Button size="md" onClick={() => window.close()}>
          Fechar
        </Button>
        <Button
          variant="solid"
          size="md"
          onClick={() => {
            const el = document.createElement("a");
            el.href = `https://corpora.bio/${urlAgenda}`;
            document.body.appendChild(el);
            el.click();
          }}
        >
          Agendar
        </Button>
      </div>
      <p className="mt-2">
        <small>
          <a href="https://usecorpora.com.br/termos-de-uso/?_gl=1*1g5b5v9*_gcl_au*MTA4NDQzNTY0MS4xNzI5NTk3MTQx">
            Termos de uso
          </a>
        </small>
        <small> &#x2022; </small>
        <small>
          <a href="https://usecorpora.com.br/politica-de-privacidade/">
            Política de privacidade
          </a>
        </small>
      </p>
    </div>
  );

  return (
    <div className="py-10 flex flex-col items-center justify-center">
      <img
        src="/img/logo/logo-light-full.png"
        className="max-w-xs"
        alt="logo"
      />

      {/* <StageWrapper selectedStage={0}>
        <Welcome />
      </StageWrapper> */}

      {stage !== 5 && (
        <>
          <div className="pt-6 pb-4 hidden md:block">
            <Steps current={stage + 1}>
              <Steps.Item title="Dados pessoais" />
              <Steps.Item title="Responsável financeiro" />
              <Steps.Item title="Endereço" />
              <Steps.Item title="Plano de saúde" />
              <Steps.Item title="Contatos" />
            </Steps>
          </div>
          <div className="py-4 flex gap-4 md:hidden">
            <Steps current={stage}>
              <Steps.Item title={stages[stage]}></Steps.Item>
            </Steps>
          </div>
        </>
      )}

      <StageWrapper selectedStage={0}>
        <DadosPessoais
          defaultValues={dadosPessoais}
          onClickLimpar={handleLimpar}
          onSubmit={(values) => {
            setItemToLS(lsDataNames.DADOS_PESSOAIS, values);
            setDadosPessoais(values);
            handleNextStage();
          }}
        />
      </StageWrapper>

      <StageWrapper selectedStage={1}>
        <ResponsavelFinanceiro
          defaultValues={responsavelFinanceiro}
          onClickLimpar={handleLimpar}
          onSubmit={(values) => {
            setItemToLS(lsDataNames.RESPONSAVEL_FINANCEIRO, values);
            setResponsavelFinanceiro(values);
            handleNextStage();
          }}
          onBack={handlePrevStage}
        />
      </StageWrapper>

      <StageWrapper selectedStage={2}>
        <Endereco
          defaultValues={endereco}
          onClickLimpar={handleLimpar}
          onBack={handlePrevStage}
          onSubmit={(values) => {
            setItemToLS(lsDataNames.ENDERECO, values);
            setEndereco(values);
            handleNextStage();
          }}
        />
      </StageWrapper>
      <StageWrapper selectedStage={3}>
        <PlanoSaude
          defaultValues={planoSaude}
          onClickLimpar={handleLimpar}
          onBack={handlePrevStage}
          isSubmitting={isSubmitting}
          onSubmit={(values) => {
            setItemToLS(lsDataNames.PLANO_SAUDE, values);
            setPlanoSaude(values);
            handleNextStage();
          }}
        />
      </StageWrapper>
      <StageWrapper selectedStage={4}>
        <Contatos
          handleFinish={handleSubmitValues}
          isSubmiting={isSubmitting}
          onBack={handlePrevStage}
          list={contacts}
          handleDeleteContact={handleDeleteContact}
          onAddContact={onAddContact}
        />
      </StageWrapper>

      <StageWrapper selectedStage={5}>
        <Completed />
      </StageWrapper>
    </div>
  );
}
